@import "~@flaticon/flaticon-uicons/css/all/all";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #0050a3;
}

body {
  margin: 0;

  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  
  background-color: #0050a3;
}

#root {
  width: 100%;
  height: 100%;
}

button {
  transition-duration: 75ms;
}